const HP_VIEW = "hp";
const CANDIDATE_VIEW = "candidate";
const FAST_TRACK_VIEW = "fast-tracker";
const ENV = import.meta.env.VITE_STAGE;

const localFeatureFlagConfig = {
    notification: (view) => {
        return [HP_VIEW, FAST_TRACK_VIEW, CANDIDATE_VIEW].includes(view);
    },
    free_trial: (view, subscribed) =>
        true && [HP_VIEW].includes(view) && !subscribed,
    free_trial_A: (currUser) => [HP_VIEW].includes(currUser.views) && !currUser.company?.subscribed && currUser.company.free_trial_type !== 2,
        free_trial_B: (currUser) => [HP_VIEW].includes(currUser.views) && !currUser.company?.subscribed && currUser.company.free_trial_type === 2,
    new_candidate_profile_switch: () => true,
    new_candidate_profile_participant_report: () => true,
    enable_placement_form_filters: () => true,
    enable_interview_scheduling: () => true,
    enable_job_pipeline: () => true,
    enable_job_application_interview_schedule: () => true,
    is_sorted_list_enabled: () => false,
    is_shortlist_to_jobs_enabled: () => true,
    enable_job_preview_v2: () => true,
    enable_job_edit_v2: () => true,
    enable_marketing_v2: () => false,
    enable_marketing_v3: () => true,
    enable_accounting_v1: () => true,
    enable_job_creation_v3: () => true,
    enable_job_creation_v31: () => true,
    enable_new_sign_in_with_otp: () => true,
    show_linkedin_info_modal: () => true,
    intercom_visible: () => false,
    pause_job: () => true,
};

const getFeatureFlagConfig = (growthbook) => {
    /************************************
     * NOTE:
     * SPECIAL HANDLING FOR LOCAL ENVIRONMENT
     *
     * In the 'local' environment, we bypass the
     * GrowthBook feature flags and use a predefined
     * set of feature flags specifically for local
     * development and testing purposes.
     ************************************/
    if (ENV === "local") {
        return localFeatureFlagConfig;
    }

    return {
        notification: (view) => {
            return [HP_VIEW, FAST_TRACK_VIEW, CANDIDATE_VIEW].includes(view);
        },
        free_trial: (view, subscribed) => true && [HP_VIEW].includes(view) && !subscribed,
        free_trial_A: (currUser) => [HP_VIEW].includes(currUser.views) && !currUser.company?.subscribed && currUser.company.free_trial_type !== 2,
        free_trial_B: (currUser) => [HP_VIEW].includes(currUser.views) && !currUser.company?.subscribed && currUser.company.free_trial_type === 2,
        new_candidate_profile_switch: () =>
            growthbook.isOn("new_candidate_profile_switch"),
        new_candidate_profile_participant_report: () =>
            growthbook.isOn("new_candidate_profile_participant_report"),
        enable_placement_form_filters: () =>
            growthbook.isOn("enable_placement_form_filters"),
        enable_interview_scheduling: () =>
            growthbook.isOn("enable_interview_scheduling"),
        enable_job_pipeline: () => growthbook.isOn("enable_job_pipeline"),
        enable_job_application_interview_schedule: () =>
            growthbook.isOn("enable_job_application_interview_schedule"),
        is_sorted_list_enabled: () => growthbook.isOn("is_sorted_list_enabled"),
        is_shortlist_to_jobs_enabled: () =>
            growthbook.isOn("is_shortlist_to_jobs_enabled"),
        enable_job_preview_v2: () => growthbook.isOn("enable_job_preview_v2"),
        enable_job_edit_v2: () => growthbook.isOn("enable_job_edit_v2"),
        enable_marketing_v2: () => growthbook.isOn("enable_marketing_v2"),
        enable_marketing_v3: () => growthbook.isOn("enable_marketing_v3"),
        enable_accounting_v1: () => growthbook.isOn("enable_accounting_v1"),
        enable_job_creation_v3: () => growthbook.isOn("enable_job_creation_v3"),
        enable_job_creation_v31: () =>
            growthbook.isOn("enable_job_creation_v31"),
        show_linkedin_info_modal: () =>
            growthbook.isOn("show_linkedin_info_modal"),
        enable_new_sign_in_with_otp: () =>
            growthbook.isOn("enable_new_sign_in_with_otp"),
        intercom_visible: () => growthbook.isOn("enable_intercom"),
        // this is not from growthbook for now
        pause_job: () => growthbook.isOn("job_lifecycle_management"),
    };
};

let FeatureFlag = {};

/**
 * Initializes the FeatureFlag object with the GrowthBook instance.
 * This should be called once, typically in your main application component.
 */
const initializeFeatureFlag = (growthbook) => {
    FeatureFlag = getFeatureFlagConfig(growthbook);
};

export { initializeFeatureFlag, FeatureFlag };
